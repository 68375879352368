.App {
  text-align: center;
  background: radial-gradient(
    201.34% 201.34% at 11.7% -7.05%,
    #000000 5.06%,
    rgba(76, 111, 201, 0.84223) 24.44%,
    rgba(134, 86, 237, 0.46496) 53.61%,
    rgba(148, 113, 241, 0.521476) 76.52%,
    rgba(195, 212, 255, 0.725392) 82.25%,
    rgba(91, 232, 223, 0.256614) 100%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: #000000;
  font-family: 'Roboto', sans-serif;

  padding-top: 40px;
  padding-bottom: 40px;

  overflow: hidden;
}

.section-one {
  color: white;
  .balance {
    width: 100%;
    text-align: start;
  }

  .balance hr {
    width: 200px;
    border-bottom: 1px solid #ccc;
    @media (min-width: 1024px) {
      width: 120px;
    }
    @media (max-width: 768px) {
      width: 80px;
    }
  }
}

.section-two {
  color: white;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  .table-back {
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    background: linear-gradient(0deg, #171717, rgba(23, 23, 23, 0));

    p {
      padding-bottom: 16px;
    }
  }
}

.section-three {
  color: white;
  text-align: start;
  height: 100vh;

  .chart-title {
    width: 100%;
    text-align: start;
  }

  .chart-title hr {
    width: 100px;
    border-bottom: 1px solid #ccc;
  }

  .chart-style {
    height: 60vh;
    @media (max-width: 1024px) {
      height: 70vh;
    }
  }
}

.section-four {
  color: white;
  font-size: 22px;

  .table-wrap {
    background: linear-gradient(316.56deg, #171717 -39.35%, rgba(23, 23, 23, 0) 54.23%);
    border-radius: 45px;
  }

  .button {
    background: linear-gradient(
      110.59deg,
      #f0cd61 -31.36%,
      rgba(255, 255, 255, 0.8) 128.45%,
      rgba(255, 255, 255, 0.37) 278.8%
    );
  }

  .feature {
    p {
      padding: 10px;
    }
    img {
      margin: 10px;
    }
  }

  table {
    .content {
      td {
        padding: 16px;
      }
    }
    .info {
      td {
        padding-top: 0px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 16px;
      }

      a {
        text-decoration:underline;
      }
    }
  }
}